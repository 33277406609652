import React, { useEffect, useContext, Fragment } from "react";
import logo from "../../Assets/pictures/logo.png";
import { ReactComponent as SubscriptionsIcon } from "../../Assets/subscriptions.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import { signOut } from "../../Redux/auth/login/actions";
import { notifAmount } from "../../Redux/notification/actions";
import { SocketContext } from "../../Context/socket";
import { UserRole } from "../../Constants/users";
import { MAIN_ROUTES } from "../../Routes";
import * as type from "../../Redux/cars/actionTypes";
function SideBar() {
  const pathname = useLocation().pathname;
  const socketio = useContext(SocketContext);

  const notification = useSelector((state) => state?.notification);
  const [isShowing, setIsShowing] = useState(false);
  const [isShowingMarketing, setIsShowingMarketing] = useState(false);
  const [isShowingSales, setIsShowingSales] = useState(false);
  const [isShowingSteerAi, setIsShowingSteerAi] = useState(false);
  const [isShowingOnMobile, setIsShowingOnMobile] = useState(false);

  const title = MAIN_ROUTES.find((route) => {
    if (route.path.includes("/:id")) {
      return pathname.includes(route.path.replace("/:id", ""));
    }
    return route.path === pathname;
  })?.title;

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(socketio);
    dispatch(notifAmount());
    socketio.on("notification", (isNotified) => {
      if (isNotified) {
        dispatch(notifAmount());
      }
    });
    return () => socketio.off("notification");
    // eslint-disable-next-line
  }, []);

  const state = useSelector((state) => state?.profile).profile;
  const next = useSelector((state) => state.nextStep).next;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (addCar) => {
    if (addCar) {
      dispatch({
        type: type.GET_CAR,
        car: null,
      });
    }
    setIsOpen(!isOpen);
  };
  return (
    <div
      className={`transition duration-300 flex relative justify-between drop-shadow-lg ${
        isOpen
          ? "fixed flex-col h-screen z-10 md:flex translate-x-0"
          : "fixed h-[82px] md:h-[60px] right-0 lg:left-0 lg:top-0 flex-ro z-10 translate-x-100 drop-shadow-md"
      }`}
    >
      <div
        className={`flex justify-between w-screen py-4 px-4 md:hidden sm:${
          isOpen ? "bg-white0" : "bg-background"
        } md:bg-opacity-0 lg:bg-white0 md:px-10`}
      >
        <div className="hidden sm:flex items-center md:hidden font-bold text-[24px]">
          {title}
        </div>
        {/* <svg
          className="filter-none drop-shadow-none block sm:hidden md:block"
          width="60"
          height="40"
          viewBox="0 0 60 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.0923 6.60848L39.7624 14.1329C39.7617 14.1418 39.7617 14.1508 39.7624 14.1598L39.5861 15.1473C39.5243 15.5068 39.3371 15.8328 39.0576 16.0676C38.7781 16.3024 38.4243 16.4309 38.059 16.4302H37.7602C37.7527 16.4228 37.7482 16.4138 37.7422 16.4078C37.4505 16.0648 37.0866 15.7902 36.6765 15.6036C36.2664 15.4169 35.8201 15.3227 35.3694 15.3278C34.3992 15.3419 33.4739 15.7381 32.7949 16.4302H26.6178C26.6104 16.4228 26.6059 16.4138 26.5984 16.4078C26.3068 16.065 25.9432 15.7905 25.5334 15.6039C25.1235 15.4172 24.6775 15.323 24.2271 15.3278C23.2602 15.3421 22.3377 15.7354 21.6586 16.4228C21.4425 16.4078 21.2319 16.3477 21.0406 16.2463C20.8493 16.1449 20.6815 16.0045 20.548 15.8341C20.4146 15.6638 20.3185 15.4673 20.2661 15.2575C20.2136 15.0477 20.2059 14.8292 20.2435 14.6162L21.9783 4.7945H39.5637C39.7901 4.7939 40.0139 4.84289 40.2192 4.93801C40.4246 5.03313 40.6066 5.17207 40.7524 5.34504C40.8981 5.518 41.0041 5.72078 41.0628 5.93908C41.1215 6.15738 41.1316 6.38588 41.0923 6.60848Z"
            fill="#4368B1"
          />
          <path
            d="M21.9798 4.79448H15.3784C15.2855 4.79433 15.1937 4.77391 15.1095 4.73465C15.0253 4.69539 14.9507 4.63825 14.8909 4.56721C14.8312 4.49617 14.7877 4.41295 14.7635 4.32337C14.7393 4.23379 14.735 4.14002 14.7508 4.0486L15.3724 0.528056C15.3984 0.380664 15.4755 0.247109 15.5903 0.15082C15.7051 0.0545301 15.8501 0.00165415 16 0.00146524H22.0665C22.1595 0.00136137 22.2514 0.0216092 22.3358 0.0607841C22.4201 0.0999591 22.4948 0.157109 22.5546 0.228212C22.6144 0.299315 22.6579 0.382643 22.682 0.472333C22.7061 0.562023 22.7102 0.655896 22.6941 0.747344L21.9798 4.79448Z"
            fill="#4368B1"
          />
          <path
            d="M39.7624 14.1329C39.7617 14.1418 39.7617 14.1508 39.7624 14.1597L39.5861 15.1473C39.5243 15.5067 39.3371 15.8327 39.0576 16.0676C38.7781 16.3024 38.4243 16.4308 38.059 16.4302H37.7602C37.7527 16.4227 37.7482 16.4138 37.7422 16.4078C37.4505 16.0648 37.0866 15.7902 36.6765 15.6035C36.2664 15.4168 35.8201 15.3227 35.3694 15.3278C34.3992 15.3419 33.4739 15.7381 32.7949 16.4302H26.6178C26.6104 16.4227 26.6059 16.4138 26.5984 16.4078C26.3068 16.065 25.9432 15.7905 25.5334 15.6038C25.1235 15.4172 24.6775 15.3229 24.2271 15.3278C23.2602 15.3421 22.3377 15.7354 21.6586 16.4227C21.4425 16.4078 21.2319 16.3477 21.0406 16.2463C20.8493 16.1449 20.6815 16.0044 20.548 15.8341C20.4146 15.6637 20.3185 15.4673 20.2661 15.2575C20.2136 15.0476 20.2059 14.8292 20.2435 14.6162L20.4199 13.6286C20.483 13.2716 20.6701 12.9481 20.9482 12.7149C21.2263 12.4817 21.5778 12.3536 21.941 12.3532H23.7699C24.0215 12.3527 24.2692 12.2912 24.4917 12.174C24.7142 12.0568 24.9049 11.8874 25.0474 11.6804L26.1561 10.0604C26.2978 9.85252 26.4883 9.68252 26.711 9.56523C26.9338 9.44794 27.1819 9.38694 27.4337 9.38758H31.2081C31.4165 9.38737 31.6229 9.4293 31.8147 9.51083C32.0066 9.59235 32.1799 9.71179 32.3242 9.86196L34.2667 11.8788C34.4111 12.0289 34.5844 12.1483 34.7763 12.2298C34.9681 12.3113 35.1744 12.3533 35.3829 12.3532H38.2338C38.4568 12.3534 38.6771 12.4017 38.8797 12.4948C39.0822 12.5879 39.2623 12.7236 39.4074 12.8926C39.5526 13.0616 39.6594 13.2599 39.7207 13.474C39.782 13.6881 39.7962 13.9128 39.7624 14.1329Z"
            fill="#282F75"
          />
          <path
            d="M39.7624 14.1329C39.7617 14.1418 39.7617 14.1508 39.7624 14.1597L39.5861 15.1

            473C39.5243 15.5067 39.3371 15.8327 39.0576 16.0676C38.7781 16.3024 38.4243 16.4308 38.059 16.4302H37.7602C37.7527 16.4227 37.7482 16.4138 37.7422 16.4078C37.4505 16.0648 37.0866 15.7902 36.6765 15.6035C36.2664 15.4168 35.8201 15.3227 35.3694 15.3278C34.3992 15.3419 33.4739 15.7381 32.7949 16.4302H26.6178C26.6104 16.4227 26.6059 16.4138 26.5984 16.4078C26.3068 16.065 25.9432 15.7905 25.5334 15.6038C25.1235 15.4172 24.6775 15.3229 24.2271 15.3278C23.2602 15.3421 22.3377 15.7354 21.6586 16.4227C21.4425 16.4078 21.2319 16.3477 21.0406 16.2463C20.8493 16.1449 20.6815 16.0044 20.548 15.8341C20.4146 15.6637 20.3185 15.4673 20.2661 15.2575C20.2136 15.0476 20.2059 14.8292 20.2435 14.6162L20.4199 13.6286C20.483 13.2716 20.6701 12.9481 20.9482 12.7149C21.2263 12.4817 21.5778 12.3536 21.941 12.3532H23.7699C24.0215 12.3527 24.2692 12.2912 24.4917 12.174C24.7142 12.0568 24.9049 11.8874 25.0474 11.6804L26.1561 10.0604C26.2978 9.85252 26.4883 9.68252 26.711 9.56523C26.9338 9.44794 27.1819 9.38694 27.4337 9.38758H31.2081C31.4165 9.38737 31.6229 9.4293 31.8147 9.51083C32.0066 9.59235 32.1799 9.71179 32.3242 9.86196L34.2667 11.8788C34.4111 12.0289 34.5844 12.1483 34.7763 12.2298C34.9681 12.3113 35.1744 12.3533 35.3829 12.3532H38.2338C38.4568 12.3534 38.6771 12.4017 38.8797 12.4948C39.0822 12.5879 39.2623 12.7236 39.4074 12.8926C39.5526 13.0616 39.6594 13.2599 39.7207 13.474C39.782 13.6881 39.7962 13.9128 39.7624 14.1329Z"
            fill="#282F75"
          />
          <path
            d="M26.1397 18.7364C25.9246 19.9507 24.7666 20.9338 23.5503 20.9338C22.334 20.9338 21.5241 19.9507 21.7378 18.7364C21.9515 17.5221 23.1125 16.5391 24.3273 16.5391C25.5421 16.5391 26.3519 17.5251 26.1397 18.7364Z"
            fill="white"
          />
          <path
            d="M37.1475 18.7364C36.9354 19.9507 35.7744 20.9338 34.5611 20.9338C33.3478 20.9338 32.5409 19.9507 32.7456 18.7364C32.9503 17.5221 34.1203 16.5391 35.3351 16.5391C36.5499 16.5391 37.3627 17.5251 37.1475 18.7364Z"
            fill="white"
          />
          <path
            d="M26.1397 18.7364C25.9246 19.9507 24.7666 20.9338 23.5503 20.9338C22.334 20.9338 21.5241 19.9507 21.7378 18.7364C21.9515 17.5221 23.1125 16.5391 24.3273 16.5391C25.5421 16.5391 26.3519 17.5251 26.1397 18.7364Z"
            fill="#4368B1"
          />
          <path
            d="M37.1475 18.7364C36.9354 19.9507 35.7744 20.9338 34.5611 20.9338C33.3478 20.9338 32.5409 19.9507 32.7456 18.7364C32.9503 17.5221 34.1203 16.5391 35.3351 16.5391C36.5499 16.5391 37.3627 17.5251 37.1475 18.7364Z"
            fill="#4368B1"
          />
          <path
            d="M2.6313 39.5256C1.53555 39.2103 0.658449 38.8086 0 38.3203L1.42398 35.6351C2.1318 36.1467 2.91868 36.5392 3.75346 36.7972C4.61104 37.0697 5.5057 37.2081 6.40568 37.2074C7.36994 37.2074 8.13199 37.0493 8.69182 36.733C9.25165 36.4168 9.53107 35.9842 9.53007 35.4352C9.53007 34.9907 9.30744 34.6495 8.86216 34.4119C8.41689 34.1742 7.70514 33.9196 6.72693 33.6481C5.91436 33.4188 5.11606 33.1419 4.3362 32.8187C3.71448 32.5513 3.16392 32.1427 2.72843 31.6253C2.27518 31.0942 2.04906 30.3911 2.05006 29.5159C2.0346 28.5523 2.33354 27.6098 2.90176 26.8307C3.46956 26.056 4.27444 25.4593 5.3164 25.0406C6.35836 24.6219 7.57017 24.4141 8.95181 24.4171C9.92498 24.4114 10.8953 24.5231 11.8416 24.7497C12.6657 24.9393 13.4528 25.2634 14.1711 25.7089L12.8547 28.3792C12.2762 27.9832 11.6345 27.6881 10.957 27.5065C10.2404 27.311 9.50051 27.2131 8.75757 27.2156C7.76143 27.2156 6.97746 27.3882 6.40568 27.7333C5.83041 28.0764 5.54352 28.5224 5.54352 29.0669C5.5405 29.2234 5.57169 29.3787 5.63491 29.5219C5.69814 29.6652 5.79188 29.7929 5.9096 29.8963C6.18222 30.1326 6.49843 30.3135 6.84049 30.4289C7.21405 30.5661 7.73901 30.7277 8.41539 30.9137C9.22329 31.12 10.0157 31.3827 10.7867 31.6998C11.3968 31.9604 11.9383 32.3581 12.3691 32.8619C12.8084 33.3721 13.028 34.0434 13.028 34.8758C13.0477 35.847 12.744 36.7972 12.1644 37.5774C11.5896 38.3461 10.7772 38.9428 9.72731 39.3675C8.67738 39.7921 7.45511 40.003 6.06052 40C4.90073 40.0045 3.74616 39.8448 2.6313 39.5256Z"
            fill="#282F75"
          />
          <path
            d="M18.9152 35.65C18.8825 35.8059 18.8679 35.9651 18.8718 36.1243C18.8718 36.8702 19.2817 37.2432 20.1016 37.2432C20.5831 37.2328 21.0527 37.0915 21.4598 36.8344L21.9559 39.2212C21.2088 39.6807 20.2455 39.9104 19.0661 39.9104C17.9703 39.9104 17.0932 39.6235 16.4348 39.0497C15.7763 38.4759 15.4456 37.6862 15.4426 36.6808C15.4481 36.3411 15.4841 36.0026 15.5502 35.6694L16.4781 30.9972H14.6851L15.208 28.4149H17.0011L17.5629 25.5955H20.9264L20.366 28.4149H23.2558L22.7388 31.0002H19.8401L18.9152 35.65Z"
            fill="#4368B1"
          />
          <path
            d="M35.9536 33.0886C35.9731 32.1464 35.7383 31.2164 35.2738 30.396C34.8246 29.6239 34.1572 29.0012 33.3552 28.6059C32.4436 28.1716 31.4423 27.958 30.4325 27.9823C29.1973 27.9605 27.9777 28.2608 26.8943 28.8535C25.8783 29.4189 25.0397 30.2551 24.4721 31.2687C23.8818 32.3242 23.5803 33.5163 23.598 34.7251C23.5781 35.6878 23.8292 36.6367 24.3227 37.464C24.8148 38.2632 25.5357 38.8966 26.3922 39.2824C27.3779 39.7241 28.4498 39.9412 29.53 39.9179C30.4218 39.9227 31.309 39.7919 32.1613 39.53C32.9677 39.2825 33.72 38.8852 34.3787 38.359L32.9772 36.1408C32.5605 36.4928 32.0809 36.7631 31.5637 36.9374C31.0193 37.1259 30.4469 37.2207 29.8707 37.2178C27.988 37.2178 26.9889 36.4282 26.8733 34.8489H35.7594C35.8818 34.2699 35.9469 33.6803 35.9536 33.0886ZM27.1109 32.8947C27.305 32.2021 27.7122 31.5879 28.2749 31.1389C28.8524 30.7064 29.56 30.4828 30.2816 30.5049C31.0726 30.5049 31.7016 30.7162 32.1688 31.1389C32.636 31.5616 32.8696 32.1468 32.8696 32.8947H27.1109Z"
            fill="#4368B1"
          />
          <path
            d="M49.4972 33.0886C49.5172 32.1466 49.2829 31.2165 48.8188 30.396C48.3691 29.6238 47.7012 29.0011 46.8988 28.6059C46.073 28.1902 45.0988 27.9824 43.9761 27.9824C42.7414 27.9607 41.5223 28.261 40.4393 28.8535C39.4221 29.4175 38.582 30.2526 38.0127 31.2657C37.4229 32.3214 37.1219 33.5135 37.1401 34.7221C37.1194 35.6854 37.3694 36.6353 37.8618 37.464C38.3541 38.2635 39.0756 38.897 39.9328 39.2824C40.9184 39.7244 41.9904 39.9415 43.0706 39.9179C43.9624 39.9227 44.8496 39.7919 45.7019 39.5301C46.5125 39.2839 47.269 38.8866 47.9313 38.359L46.5282 36.1408C46.1124 36.4933 45.6332 36.7636 45.1162 36.9374C44.5718 37.1256 43.9994 37.2205 43.4233 37.2178C41.5396 37.2178 40.5404 36.4282 40.4259 34.8489H49.3104C49.4304 34.2697 49.4929 33.6801 49.4972 33.0886ZM40.6545 32.8947C40.849 32.2019 41.2567 31.5877 41.82 31.1389C42.3969 30.7064 43.1041 30.4828 43.8252 30.5049C44.6161 30.5049 45.2452 30.7162 45.7124 31.1389C46.1796 31.5616 46.4132 32.1468 46.4132 32.8947H40.6545Z"
            fill="#4368B1"
          />
          <path
            d="M57.4942 28.3508C58.3062 28.0922 59.1553 27.9687 60.0075 27.9854L59.4098 31.0852C59.0079 31.0569 58.7494 31.042 58.6343 31.042C57.6123 31.042 56.7964 31.2936 56.1868 31.7968C55.5772 32.3 55.1638 33.0822 54.9466 34.1433L53.8245 39.7419H50.446L52.7546 28.1569H55.9462L55.6653 29.5353C56.165 28.9927 56.7942 28.5852 57.4942 28.3508Z"
            fill="#4368B1"
          />
        </svg> */}
        {isOpen ? (
          <button
            className="w-[42px] h-[42px] flex justify-center items-center md:hidden z-10"
            onClick={toggleMenu}
          >
            <svg
              className=" cursor-pointer"
              onClick={toggleMenu}
              width="20"
              height="20"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.59 0L5 3.59L1.41 0L0 1.41L3.59 5L0 8.59L1.41 10L5 6.41L8.59 10L10 8.59L6.41 5L10 1.41L8.59 0Z"
                fill="#2E3A59"
              />
            </svg>
          </button>
        ) : (
          <button className="lg:hidden z-10" onClick={toggleMenu}>
            <svg
              width="42"
              height="42"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.7458 17.4167H6.25417C5.42344 17.4167 4.75 18.0901 4.75 18.9209V19.0792C4.75 19.9099 5.42344 20.5834 6.25417 20.5834H31.7458C32.5766 20.5834 33.25 19.9099 33.25 19.0792V18.9209C33.25 18.0901 32.5766 17.4167 31.7458 17.4167Z"
                fill="#282F75"
              />
              <path
                d="M31.7458 25.3333H6.25417C5.42344 25.3333 4.75 26.0068 4.75 26.8375V26.9958C4.75 27.8265 5.42344 28.5 6.25417 28.5H31.7458C32.5766 28.5 33.25 27.8265 33.25 26.9958V26.8375C33.25 26.0068 32.5766 25.3333 31.7458 25.3333Z"
                fill="#282F75"
              />
              <path
                d="M31.7458 9.5H6.25417C5.42344 9.5 4.75 10.1734 4.75 11.0042V11.1625C4.75 11.9932 5.42344 12.6667 6.25417 12.6667H31.7458C32.5766 12.6667 33.25 11.9932 33.25 11.1625V11.0042C33.25 10.1734 32.5766 9.5 31.7458 9.5Z"
                fill="#282F75"
              />
            </svg>
          </button>
        )}
      </div>
      <div className={` flex-1 bg-white0 h-screen`}>
        <ul
          className={`list-none ${isOpen ? " " : "hidden"} md:block relative`}
        >
          <div className=" lg:flex justify-center hidden w-screen lg:w-72 m-auto">
            <img
              className="w-[62px] lg:w-[80px]  gap-x-2 flex mx-3 my-5 self-center lg:flex"
              src={logo}
              alt="logo"
            />
          </div>

          <li className="hidden lg:flex ">
            <Menu
              as="div"
              className=" inline-block text-left left-0  mx-5 my-5  lg:w-full"
            >
              <Menu.Button
                onClick={(e) => e.stopPropagation()}
                className="inline-flex w-full  lg:w-full lg:justify-between lg:flex bg-white text-sm font-medium"
              >
                <div className="hidden lg:flex lg:justify-between lg:w-full">
                  <div className="font-bold text-[18px]">
                    {/* {state.firstName + " " + state.lastName} */}
                    {state?.firstName !== undefined
                      ? state?.firstName + " " + state?.lastName
                      : state?.role}
                  </div>
                  <div>
                    <svg
                      sidebar-toggle-item="true"
                      className="w-6 h-6 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {pathname === "/profile" || pathname === "/profile" ? (
                        <path
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          fill="#4368B1"
                        />
                      ) : (
                        <path
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          fill="#282F75"
                        />
                      )}
                    </svg>
                  </div>
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="border-2 border-grey2  left-50  mt-2  rounded-md bg-white0 shadow-lg  focus:outline-none p-3 absolute">
                  <div className="divide-y divide-grey1">
                    <Menu.Item>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a href="#" className={classNames("text-black ")}>
                        <div className="flex flex-col px-4 ">
                          <p className="font-bold">
                            {state?.firstName !== undefined
                              ? state?.firstName + " " + state?.lastName
                              : state?.role}
                          </p>
                          <p className="font-medium text-grey1">
                            {state?.email}
                          </p>
                        </div>
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <div className="flex flex-row items-center my-2 py-2">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2311 9.86546L13.8999 9.09671C14.0343 8.37171 14.0343 7.62796 13.8999 6.90296L15.2311 6.13421C15.3843 6.04671 15.453 5.86546 15.403 5.69671C15.0561 4.58421 14.4655 3.57796 13.6936 2.74046C13.5749 2.61233 13.3811 2.58108 13.2311 2.66858L11.8999 3.43733C11.3405 2.95608 10.6968 2.58421 9.99989 2.34046V0.806084C9.99989 0.631084 9.87802 0.477959 9.70614 0.440459C8.55927 0.184209 7.38427 0.196709 6.29365 0.440459C6.12177 0.477959 5.9999 0.631084 5.9999 0.806084V2.34358C5.30615 2.59046 4.6624 2.96233 4.0999 3.44046L2.77177 2.67171C2.61865 2.58421 2.42802 2.61233 2.30927 2.74358C1.5374 3.57796 0.946773 4.58421 0.599898 5.69983C0.546773 5.86858 0.618648 6.04983 0.771773 6.13733L2.10302 6.90608C1.96865 7.63108 1.96865 8.37483 2.10302 9.09983L0.771773 9.86858C0.618648 9.95608 0.549898 10.1373 0.599898 10.3061C0.946773 11.4186 1.5374 12.4248 2.30927 13.2623C2.42802 13.3905 2.62177 13.4217 2.77177 13.3342L4.10302 12.5655C4.6624 13.0467 5.30615 13.4186 6.00302 13.6623V15.1998C6.00302 15.3748 6.1249 15.528 6.29677 15.5655C7.44365 15.8217 8.61864 15.8092 9.70927 15.5655C9.88114 15.528 10.003 15.3748 10.003 15.1998V13.6623C10.6968 13.4155 11.3405 13.0436 11.903 12.5655L13.2343 13.3342C13.3874 13.4217 13.578 13.3936 13.6968 13.2623C14.4686 12.428 15.0593 11.4217 15.4061 10.3061C15.453 10.1342 15.3843 9.95296 15.2311 9.86546ZM7.99989 10.4998C6.62177 10.4998 5.4999 9.37796 5.4999 7.99983C5.4999 6.62171 6.62177 5.49983 7.99989 5.49983C9.37802 5.49983 10.4999 6.62171 10.4999 7.99983C10.4999 9.37796 9.37802 10.4998 7.99989 10.4998Z"
                            fill="#282F75"
                          />
                        </svg>
                        <Link to="/profile">
                          <p className="ml-2">Paramètres du compte</p>
                        </Link>
                      </div>
                    </Menu.Item>
                  </div>
                  <Menu.Item>
                    <div className="flex flex-row  items-center">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.2222 16H6.22222C5.24038 16 4.44444 15.2041 4.44444 14.2222V10.6667H6.22222V14.2222H14.2222V1.77778H6.22222V5.33333H4.44444V1.77778C4.44444 0.795938 5.24038 0 6.22222 0H14.2222C15.2041 0 16 0.795938 16 1.77778V14.2222C16 15.2041 15.2041 16 14.2222 16ZM8 11.5556V8.88889H0V7.11111H8V4.44444L12.4444 8L8 11.5556Z"
                          fill="#282F75"
                        />
                      </svg>
                      <a href="/" className="ml-2" onClick={signOut}>
                        Déconnexion
                      </a>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          <li className="flex flex-col lg:hidden">
            <button
              type="button"
              className={
                pathname === "/profile"
                  ? "border-transparent border-l-4 border-lightblue1 flex items-center w-full font-medium text-gray-900 transition duration-75 group "
                  : "flex items-center w-full font-medium text-gray-900 transition duration-75 group md:py-4 py-6 hover:bg-grey3"
              }
            >
              <li
                onClick={() => setIsShowingOnMobile((isShowing) => !isShowing)}
                className={
                  pathname === "/profile" || pathname === "/profile"
                    ? "flex-1 ml-3 text-left whitespace-nowrap  text-lightblue1  font-medium"
                    : "flex-1 ml-3 text-left whitespace-nowrap  text-border-lightblue1 font-medium"
                }
              >
                <p className="px-3 font-bold">
                  {/* {state.firstName + " " + state.lastName} */}
                  {state?.firstName !== undefined
                    ? state?.firstName + " " + state?.lastName
                    : state?.role}
                </p>
              </li>
              <li className=" px-5">
                <svg
                  onClick={() =>
                    setIsShowingOnMobile((isShowing) => !isShowing)
                  }
                  sidebar-toggle-item="true"
                  className="w-6 h-6 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {pathname === "/profile" ? (
                    <path
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      fill="#4368B1"
                    />
                  ) : (
                    <path
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      fill="#282F75"
                    />
                  )}
                </svg>
              </li>
            </button>

            <Transition
              show={isShowingOnMobile}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ul>
                <li>
                  <p className="py-4 px-5 ">{state?.email}</p>
                </li>
                <li>
                  <Link
                    to="/"
                    className="flex items-center p-2  w-full font-medium transition duration-75 group hover:bg-grey3 "
                  >
                    <div className="flex flex-row p-3 items-center">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="#282F75"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2311 9.86546L13.8999 9.09671C14.0343 8.37171 14.0343 7.62796 13.8999 6.90296L15.2311 6.13421C15.3843 6.04671 15.453 5.86546 15.403 5.69671C15.0561 4.58421 14.4655 3.57796 13.6936 2.74046C13.5749 2.61233 13.3811 2.58108 13.2311 2.66858L11.8999 3.43733C11.3405 2.95608 10.6968 2.58421 9.99989 2.34046V0.806084C9.99989 0.631084 9.87802 0.477959 9.70614 0.440459C8.55927 0.184209 7.38427 0.196709 6.29365 0.440459C6.12177 0.477959 5.9999 0.631084 5.9999 0.806084V2.34358C5.30615 2.59046 4.6624 2.96233 4.0999 3.44046L2.77177 2.67171C2.61865 2.58421 2.42802 2.61233 2.30927 2.74358C1.5374 3.57796 0.946773 4.58421 0.599898 5.69983C0.546773 5.86858 0.618648 6.04983 0.771773 6.13733L2.10302 6.90608C1.96865 7.63108 1.96865 8.37483 2.10302 9.09983L0.771773 9.86858C0.618648 9.95608 0.549898 10.1373 0.599898 10.3061C0.946773 11.4186 1.5374 12.4248 2.30927 13.2623C2.42802 13.3905 2.62177 13.4217 2.77177 13.3342L4.10302 12.5655C4.6624 13.0467 5.30615 13.4186 6.00302 13.6623V15.1998C6.00302 15.3748 6.1249 15.528 6.29677 15.5655C7.44365 15.8217 8.61864 15.8092 9.70927 15.5655C9.88114 15.528 10.003 15.3748 10.003 15.1998V13.6623C10.6968 13.4155 11.3405 13.0436 11.903 12.5655L13.2343 13.3342C13.3874 13.4217 13.578 13.3936 13.6968 13.2623C14.4686 12.428 15.0593 11.4217 15.4061 10.3061C15.453 10.1342 15.3843 9.95296 15.2311 9.86546ZM7.99989 10.4998C6.62177 10.4998 5.4999 9.37796 5.4999 7.99983C5.4999 6.62171 6.62177 5.49983 7.99989 5.49983C9.37802 5.49983 10.4999 6.62171 10.4999 7.99983C10.4999 9.37796 9.37802 10.4998 7.99989 10.4998Z"
                          fill="#282F75"
                        />
                      </svg>
                      <Link to="/profile" onClick={() => toggleMenu()}>
                        <p className="ml-2">Account Settings</p>
                      </Link>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="flex flex-row p-5 items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#282F75"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.2222 16H6.22222C5.24038 16 4.44444 15.2041 4.44444 14.2222V10.6667H6.22222V14.2222H14.2222V1.77778H6.22222V5.33333H4.44444V1.77778C4.44444 0.795938 5.24038 0 6.22222 0H14.2222C15.2041 0 16 0.795938 16 1.77778V14.2222C16 15.2041 15.2041 16 14.2222 16ZM8 11.5556V8.88889H0V7.11111H8V4.44444L12.4444 8L8 11.5556Z"
                        fill="#282F75"
                      />
                    </svg>
                    <a href="/" className="ml-2" onClick={signOut}>
                      Déconnexion
                    </a>
                  </div>
                </li>
              </ul>
            </Transition>
          </li>

          <>
            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li
                className={
                  pathname === "/"
                    ? "flex md:flex hover:bg-grey3"
                    : " flex md:flex hover:bg-grey3"
                }
              >
                <div className="py-4 px-5 flex  items-center space-x-3">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {pathname === "/" ? (
                      <path
                        d="M15.5232 8.94116H8.54412L13.1921 13.5891C13.3697 13.7667 13.6621 13.7812 13.8447 13.6091C14.9829 12.5367 15.7659 11.0912 15.9956 9.46616C16.035 9.18793 15.8041 8.94116 15.5232 8.94116ZM15.0576 7.03528C14.8153 3.52176 12.0076 0.714119 8.49412 0.471767C8.22589 0.453237 8 0.679413 8 0.948236V7.5294H14.5815C14.8503 7.5294 15.0762 7.30352 15.0576 7.03528ZM6.58824 8.94116V1.96206C6.58824 1.68118 6.34147 1.45029 6.06353 1.48971C2.55853 1.985 -0.120585 5.04705 0.00412089 8.71675C0.132356 12.4856 3.37736 15.5761 7.14794 15.5288C8.6303 15.5103 10 15.0326 11.1262 14.2338C11.3585 14.0691 11.3738 13.727 11.1724 13.5256L6.58824 8.94116Z"
                        fill="#4368B1"
                      />
                    ) : (
                      <path
                        d="M15.5232 8.94116H8.54412L13.1921 13.5891C13.3697 13.7667 13.6621 13.7812 13.8447 13.6091C14.9829 12.5367 15.7659 11.0912 15.9956 9.46616C16.035 9.18793 15.8041 8.94116 15.5232 8.94116ZM15.0576 7.03528C14.8153 3.52176 12.0076 0.714119 8.49412 0.471767C8.22589 0.453237 8 0.679413 8 0.948236V7.5294H14.5815C14.8503 7.5294 15.0762 7.30352 15.0576 7.03528ZM6.58824 8.94116V1.96206C6.58824 1.68118 6.34147 1.45029 6.06353 1.48971C2.55853 1.985 -0.120585 5.04705 0.00412089 8.71675C0.132356 12.4856 3.37736 15.5761 7.14794 15.5288C8.6303 15.5103 10 15.0326 11.1262 14.2338C11.3585 14.0691 11.3738 13.727 11.1724 13.5256L6.58824 8.94116Z"
                        fill="#282F75"
                      />
                    )}
                  </svg>

                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "text-lightblue1 font-medium"
                        : "text-border-lightblue1 font-medium"
                    }
                    onClick={() => toggleMenu()}
                  >
                    Accueil
                  </NavLink>
                </div>
              </li>
            )}

            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li className="flex md:flex flex-col ">
                <button
                  type="button"
                  className={
                    pathname === "/cars" || pathname === "/addCar"
                      ? "p-2 py-4 flex items-center px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                      : "flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                  }
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {pathname === "/cars" || pathname === "/addCar" ? (
                      <path
                        d="M0 12.4444V8C0 7.5285 0.187301 7.07632 0.520699 6.74292C0.854097 6.40952 1.30628 6.22222 1.77778 6.22222V3.55556C1.77778 2.61256 2.15238 1.70819 2.81918 1.0414C3.48597 0.374602 4.39034 0 5.33333 0H10.6667C11.6097 0 12.514 0.374602 13.1808 1.0414C13.8476 1.70819 14.2222 2.61256 14.2222 3.55556V6.22222C14.6937 6.22222 15.1459 6.40952 15.4793 6.74292C15.8127 7.07632 16 7.5285 16 8V12.4444C16 12.9159 15.8127 13.3681 15.4793 13.7015C15.1459 14.0349 14.6937 14.2222 14.2222 14.2222C13.7507 14.2222 13.2985 14.0349 12.9651 13.7015C12.6317 13.3681 12.4444 12.9159 12.4444 12.4444H3.55556C3.55556 12.9159 3.36825 13.3681 3.03486 13.7015C2.70146 14.0349 2.24927 14.2222 1.77778 14.2222C1.30628 14.2222 0.854097 14.0349 0.520699 13.7015C0.187301 13.3681 0 12.9159 0 12.4444ZM5.33333 1.77778C4.86184 1.77778 4.40965 1.96508 4.07625 2.29848C3.74286 2.63187 3.55556 3.08406 3.55556 3.55556V6.22222H12.4444V3.55556C12.4444 3.08406 12.2571 2.63187 11.9237 2.29848C11.5903 1.96508 11.1382 1.77778 10.6667 1.77778H5.33333ZM2.66667 9.77778C2.90241 9.77778 3.12851 9.68413 3.29521 9.51743C3.4619 9.35073 3.55556 9.12464 3.55556 8.88889C3.55556 8.65314 3.4619 8.42705 3.29521 8.26035C3.12851 8.09365 2.90241 8 2.66667 8C2.43092 8 2.20483 8.09365 2.03813 8.26035C1.87143 8.42705 1.77778 8.65314 1.77778 8.88889C1.77778 9.12464 1.87143 9.35073 2.03813 9.51743C2.20483 9.68413 2.43092 9.77778 2.66667 9.77778ZM13.3333 9.77778C13.5691 9.77778 13.7952 9.68413 13.9619 9.51743C14.1286 9.35073 14.2222 9.12464 14.2222 8.88889C14.2222 8.65314 14.1286 8.42705 13.9619 8.26035C13.7952 8.09365 13.5691 8 13.3333 8C13.0976 8 12.8715 8.09365 12.7048 8.26035C12.5381 8.42705 12.4444 8.65314 12.4444 8.88889C12.4444 9.12464 12.5381 9.35073 12.7048 9.51743C12.8715 9.68413 13.0976 9.77778 13.3333 9.77778Z"
                        fill="#4368B1"
                      />
                    ) : (
                      <path
                        d="M0 12.4444V8C0 7.5285 0.187301 7.07632 0.520699 6.74292C0.854097 6.40952 1.30628 6.22222 1.77778 6.22222V3.55556C1.77778 2.61256 2.15238 1.70819 2.81918 1.0414C3.48597 0.374602 4.39034 0 5.33333 0H10.6667C11.6097 0 12.514 0.374602 13.1808 1.0414C13.8476 1.70819 14.2222 2.61256 14.2222 3.55556V6.22222C14.6937 6.22222 15.1459 6.40952 15.4793 6.74292C15.8127 7.07632 16 7.5285 16 8V12.4444C16 12.9159 15.8127 13.3681 15.4793 13.7015C15.1459 14.0349 14.6937 14.2222 14.2222 14.2222C13.7507 14.2222 13.2985 14.0349 12.9651 13.7015C12.6317 13.3681 12.4444 12.9159 12.4444 12.4444H3.55556C3.55556 12.9159 3.36825 13.3681 3.03486 13.7015C2.70146 14.0349 2.24927 14.2222 1.77778 14.2222C1.30628 14.2222 0.854097 14.0349 0.520699 13.7015C0.187301 13.3681 0 12.9159 0 12.4444ZM5.33333 1.77778C4.86184 1.77778 4.40965 1.96508 4.07625 2.29848C3.74286 2.63187 3.55556 3.08406 3.55556 3.55556V6.22222H12.4444V3.55556C12.4444 3.08406 12.2571 2.63187 11.9237 2.29848C11.5903 1.96508 11.1382 1.77778 10.6667 1.77778H5.33333ZM2.66667 9.77778C2.90241 9.77778 3.12851 9.68413 3.29521 9.51743C3.4619 9.35073 3.55556 9.12464 3.55556 8.88889C3.55556 8.65314 3.4619 8.42705 3.29521 8.26035C3.12851 8.09365 2.90241 8 2.66667 8C2.43092 8 2.20483 8.09365 2.03813 8.26035C1.87143 8.42705 1.77778 8.65314 1.77778 8.88889C1.77778 9.12464 1.87143 9.35073 2.03813 9.51743C2.20483 9.68413 2.43092 9.77778 2.66667 9.77778ZM13.3333 9.77778C13.5691 9.77778 13.7952 9.68413 13.9619 9.51743C14.1286 9.35073 14.2222 9.12464 14.2222 8.88889C14.2222 8.65314 14.1286 8.42705 13.9619 8.26035C13.7952 8.09365 13.5691 8 13.3333 8C13.0976 8 12.8715 8.09365 12.7048 8.26035C12.5381 8.42705 12.4444 8.65314 12.4444 8.88889C12.4444 9.12464 12.5381 9.35073 12.7048 9.51743C12.8715 9.68413 13.0976 9.77778 13.3333 9.77778Z"
                        fill="#282F75"
                      />
                    )}
                  </svg>

                  <li
                    onClick={() => setIsShowing((isShowing) => !isShowing)}
                    className={
                      pathname === "/cars" || pathname === "/addCar"
                        ? "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-lightblue1  font-medium"
                        : "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-border-lightblue1 font-medium"
                    }
                  >
                    Voitures
                  </li>
                  <svg
                    onClick={() => setIsShowing((isShowing) => !isShowing)}
                    sidebar-toggle-item="true"
                    className="w-6 h-6 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {pathname === "/cars" || pathname === "/addCar" ? (
                      <path
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill="#4368B1"
                      />
                    ) : (
                      <path
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill="#282F75"
                      />
                    )}
                  </svg>
                </button>
                <Transition
                  show={isShowing}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ul>
                    <li>
                      <Link
                        to="/cars"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Tableau
                      </Link>
                    </li>
                    {(state?.role === UserRole.OWNER ||
                      state?.role === UserRole.ADMIN) && (
                      <li>
                        <Link
                          to="/addCar"
                          className="flex items-center p-2 pl-11 w-full font-medium   transition duration-75 group hover:bg-grey3"
                          onClick={() => toggleMenu(true)}
                        >
                          Ajouter
                        </Link>
                      </li>
                    )}
                  </ul>
                </Transition>
              </li>
            )}

            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li className="flex md:flex flex-col ">
                <button
                  type="button"
                  className={
                    pathname === "/marketing" ||
                    pathname === "/marketing/multi-diffusion"
                      ? " flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                      : "flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                  }
                >
                  <svg
                    width="17"
                    height="14"
                    viewBox="0 0 17 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.82901 8.20221L2.91895 11.6849C2.93802 12.437 3.58399 13.0843 4.37912 13.1039C5.16583 13.1233 5.78775 12.5211 5.76757 11.7565L5.71405 9.68169L6.78231 9.7085C6.97899 9.71329 7.13443 9.5626 7.12945 9.37168L7.1062 8.47242C7.15984 8.49156 7.51286 8.61672 13.7384 10.8239C14.4543 11.1897 15.2849 10.7132 15.2646 9.93721L15.2051 7.63899L16.4174 7.07374C16.5366 7.01808 16.6097 6.90039 16.6063 6.76939L16.5706 5.38634C16.5672 5.25533 16.4874 5.13366 16.3656 5.07214L15.1227 4.44533L15.0568 1.89387C15.0473 1.52427 14.8432 1.18599 14.5111 0.989025C14.5071 0.986582 14.5032 0.984324 14.4992 0.981996C14.1761 0.795444 13.7952 0.777743 13.4734 0.933783L6.5525 3.48297L3.06058 3.39528C1.68728 3.36058 0.595533 4.41559 0.630571 5.75306C0.662133 6.97574 1.62308 8.00434 2.82901 8.20221ZM5.69623 8.99005L5.67835 8.29852L6.39052 8.3164L6.40841 9.00793L5.69623 8.99005ZM15.8639 5.58213L15.8886 6.53784L15.1852 6.86578L15.1427 5.21842L15.8639 5.58213ZM13.81 1.55934C13.9687 1.4783 14.1096 1.54184 14.1629 1.57441C14.2159 1.60582 14.34 1.69832 14.3446 1.8758L14.5524 9.91934C14.5593 10.1812 14.2748 10.3413 14.0339 10.2096C13.9907 10.1859 13.9668 10.1818 13.8465 10.1379L13.6265 1.62962C13.7638 1.57832 13.7797 1.57487 13.81 1.55934Z"
                      fill={
                        pathname === "/marketing" ||
                        pathname === "/marketing/multi-diffusion"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                  </svg>

                  <li
                    onClick={() =>
                      setIsShowingMarketing(
                        (isShowingMarketing) => !isShowingMarketing
                      )
                    }
                    className={
                      pathname === "/marketing" ||
                      pathname === "/marketing/multi-diffusion"
                        ? "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-lightblue1  font-medium"
                        : "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-border-lightblue1 font-medium"
                    }
                  >
                    Marketing
                  </li>
                  <svg
                    onClick={() =>
                      setIsShowingMarketing(
                        (isShowingMarketing) => !isShowingMarketing
                      )
                    }
                    sidebar-toggle-item="true"
                    className="w-6 h-6 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      fill={
                        pathname === "/marketing" ||
                        pathname === "/marketing/multi-diffusion"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                  </svg>
                </button>
                <Transition
                  show={isShowingMarketing}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ul>
                    <li>
                      <Link
                        to="/marketing/multi-diffusion"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Multi-diffusion
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/sales/analytics"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Analytiques
                      </Link>
                    </li>
                  </ul>
                </Transition>
              </li>
            )}
            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li className="flex md:flex flex-col ">
                <button
                  type="button"
                  className={
                    pathname === "/sales" || pathname === "/sales/dashboard"
                      ? "flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                      : "flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                  }
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.375 9.25311C3.68566 9.25311 3.9375 9.50495 3.9375 9.81561V14.625H14.0625V9.81561C14.0625 9.50495 14.3143 9.25311 14.625 9.25311C14.9357 9.25311 15.1875 9.50495 15.1875 9.81561V14.625C15.1875 14.9234 15.069 15.2095 14.858 15.4205C14.647 15.6315 14.3609 15.75 14.0625 15.75H3.9375C3.63913 15.75 3.35298 15.6315 3.142 15.4205C2.93103 15.2095 2.8125 14.9234 2.8125 14.625V9.81561C2.8125 9.50495 3.06434 9.25311 3.375 9.25311Z"
                      fill={
                        pathname === "/sales" || pathname === "/sales/dashboard"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.79093 2.25003C3.79291 2.25001 3.79489 2.25 3.79688 2.25H14.2078C14.4503 2.252 14.6858 2.33186 14.8795 2.4778C15.0733 2.62373 15.215 2.82804 15.2839 3.06061L15.2855 3.0662L16.291 6.5959C16.3393 6.76558 16.3052 6.9481 16.1989 7.08891C16.0926 7.22972 15.9264 7.3125 15.75 7.3125H2.25C2.07358 7.3125 1.90737 7.22972 1.80107 7.08891C1.69477 6.9481 1.66069 6.76558 1.70903 6.5959L2.71609 3.0606C2.78496 2.82803 2.92673 2.62373 3.12047 2.4778C3.31386 2.33213 3.54885 2.25231 3.79093 2.25003ZM3.80105 3.375C3.80089 3.375 3.8012 3.37499 3.80105 3.375L3.79225 2.25002C3.79181 2.25002 3.79137 2.25003 3.79093 2.25003M3.80105 3.375H3.79688C3.79668 3.375 3.79648 3.375 3.79629 3.375L3.79645 3.37442L3.49883 3.28964C3.50169 3.29142 3.50456 3.29319 3.50745 3.29492M3.77669 3.37464C3.78319 3.37487 3.78973 3.37499 3.79629 3.375L3.79501 3.37946C3.79494 3.37964 3.79488 3.37983 3.79483 3.38002L3.77669 3.37464ZM2.99512 6.1875H2.25V6.75L2.79098 6.9041L2.99512 6.1875ZM15.0049 6.1875L15.209 6.9041L15.75 6.75V6.1875H15.0049ZM14.205 3.37946L14.2036 3.37442L14.7445 3.22031L14.2052 3.38002C14.2051 3.37983 14.2051 3.37964 14.205 3.37946ZM14.1985 3.37498C14.1983 3.37497 14.1987 3.37498 14.1985 3.37498L14.2031 2.8125V3.375L14.1985 3.37498Z"
                      fill={
                        pathname === "/sales" || pathname === "/sales/dashboard"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.25 6.1875C2.56066 6.1875 2.8125 6.43934 2.8125 6.75V7.875C2.8125 8.32255 2.99029 8.75177 3.30676 9.06824C3.62322 9.38471 4.05245 9.5625 4.5 9.5625C4.94755 9.5625 5.37678 9.38471 5.69324 9.06824C6.00971 8.75177 6.1875 8.32255 6.1875 7.875V6.75C6.1875 6.43934 6.43934 6.1875 6.75 6.1875C7.06066 6.1875 7.3125 6.43934 7.3125 6.75V7.875C7.3125 8.62092 7.01618 9.33629 6.48874 9.86374C5.96129 10.3912 5.24592 10.6875 4.5 10.6875C3.75408 10.6875 3.03871 10.3912 2.51126 9.86374C1.98382 9.33629 1.6875 8.62092 1.6875 7.875V6.75C1.6875 6.43934 1.93934 6.1875 2.25 6.1875Z"
                      fill={
                        pathname === "/sales" || pathname === "/sales/dashboard"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.75 6.1875C7.06066 6.1875 7.3125 6.43934 7.3125 6.75V7.875C7.3125 8.32255 7.49029 8.75177 7.80676 9.06824C8.12322 9.38471 8.55245 9.5625 9 9.5625C9.44755 9.5625 9.87677 9.38471 10.1932 9.06824C10.5097 8.75177 10.6875 8.32255 10.6875 7.875V6.75C10.6875 6.43934 10.9393 6.1875 11.25 6.1875C11.5607 6.1875 11.8125 6.43934 11.8125 6.75V7.875C11.8125 8.62092 11.5162 9.33629 10.9887 9.86374C10.4613 10.3912 9.74592 10.6875 9 10.6875C8.25408 10.6875 7.53871 10.3912 7.01126 9.86374C6.48382 9.33629 6.1875 8.62092 6.1875 7.875V6.75C6.1875 6.43934 6.43934 6.1875 6.75 6.1875Z"
                      fill={
                        pathname === "/sales" || pathname === "/sales/dashboard"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.25 6.1875C11.5607 6.1875 11.8125 6.43934 11.8125 6.75V7.875C11.8125 8.32255 11.9903 8.75177 12.3068 9.06824C12.6232 9.38471 13.0524 9.5625 13.5 9.5625C13.9476 9.5625 14.3768 9.38471 14.6932 9.06824C15.0097 8.75177 15.1875 8.32255 15.1875 7.875V6.75C15.1875 6.43934 15.4393 6.1875 15.75 6.1875C16.0607 6.1875 16.3125 6.43934 16.3125 6.75V7.875C16.3125 8.62092 16.0162 9.33629 15.4887 9.86374C14.9613 10.3912 14.2459 10.6875 13.5 10.6875C12.7541 10.6875 12.0387 10.3912 11.5113 9.86374C10.9838 9.33629 10.6875 8.62092 10.6875 7.875V6.75C10.6875 6.43934 10.9393 6.1875 11.25 6.1875Z"
                      fill={
                        pathname === "/sales" || pathname === "/sales/dashboard"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                  </svg>

                  <li
                    onClick={() =>
                      setIsShowingSales((isShowingSales) => !isShowingSales)
                    }
                    className={
                      pathname === "/sales" || pathname === "/sales/dashboard"
                        ? "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-lightblue1  font-medium"
                        : "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-border-lightblue1 font-medium"
                    }
                  >
                    Ventes
                  </li>
                  <svg
                    onClick={() =>
                      setIsShowingSales((isShowingSales) => !isShowingSales)
                    }
                    sidebar-toggle-item="true"
                    className="w-6 h-6 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      fill={
                        pathname === "/sales" || pathname === "/sales/dashboard"
                          ? "#4368B1"
                          : "#282F75"
                      }
                    />
                  </svg>
                </button>
                <Transition
                  show={isShowingSales}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ul>
                    <li>
                      <Link
                        to="/sales/dashboard"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Tableau de board
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sales/vehicle-sheet"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Fiches de vehicules
                      </Link>
                    </li>
                  </ul>
                </Transition>
              </li>
            )}

            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li
                className={
                  pathname === "/insights"
                    ? " lg:flex hover:bg-grey3 font-medium"
                    : " lg:flex font-medium hover:bg-grey3"
                }
              >
                <div className="py-4 px-5 flex  items-center space-x-3  ">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {pathname === "/insights" ? (
                      <path
                        d="M14.625 13.5H12.25V5.16663H14.625V13.5ZM10.6667 13.5H8.29167V2.66663H10.6667V13.5ZM6.70833 13.5H4.33333V0.166626H6.70833V13.5ZM2.75 13.5H0.375V6.83329H2.75V13.5Z"
                        fill="#4368B1"
                      />
                    ) : (
                      <path
                        d="M14.625 13.5H12.25V5.16663H14.625V13.5ZM10.6667 13.5H8.29167V2.66663H10.6667V13.5ZM6.70833 13.5H4.33333V0.166626H6.70833V13.5ZM2.75 13.5H0.375V6.83329H2.75V13.5Z"
                        fill="#282F75"
                      />
                    )}
                  </svg>
                  <NavLink
                    to="/insights"
                    className={({ isActive }) =>
                      isActive
                        ? "text-lightblue1 font-medium flex items-center"
                        : "text-border-lightblue1 font-medium flex items-center"
                    }
                    onClick={() => toggleMenu()}
                  >
                    Insights
                  </NavLink>
                </div>
              </li>
            )}
            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li
                className={
                  pathname === "/notifications"
                    ? " lg:flex hover:bg-grey3 font-medium"
                    : " lg:flex font-medium hover:bg-grey3"
                }
              >
                <div className="py-4 px-5 flex items-center space-x-3  ">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {pathname === "/notifications" ? (
                      <path
                        d="M8 20C6.89605 19.9946 6.00092 19.1039 5.99 18H9.99C9.99211 18.2674 9.94113 18.5325 9.84 18.78C9.57766 19.382 9.04181 19.8211 8.4 19.96H8.395H8.38H8.362H8.353C8.23682 19.9842 8.11865 19.9976 8 20ZM16 17H0V15L2 14V8.5C1.94732 7.08912 2.26594 5.68913 2.924 4.44C3.57904 3.28151 4.6987 2.45888 6 2.18V0H10V2.18C12.579 2.794 14 5.038 14 8.5V14L16 15V17Z"
                        fill="#4368B1"
                      />
                    ) : (
                      <path
                        d="M8 20C6.89605 19.9946 6.00092 19.1039 5.99 18H9.99C9.99211 18.2674 9.94113 18.5325 9.84 18.78C9.57766 19.382 9.04181 19.8211 8.4 19.96H8.395H8.38H8.362H8.353C8.23682 19.9842 8.11865 19.9976 8 20ZM16 17H0V15L2 14V8.5C1.94732 7.08912 2.26594 5.68913 2.924 4.44C3.57904 3.28151 4.6987 2.45888 6 2.18V0H10V2.18C12.579 2.794 14 5.038 14 8.5V14L16 15V17Z"
                        fill="#282F75"
                      />
                    )}
                  </svg>
                  <NavLink
                    to="/notifications"
                    className={({ isActive }) =>
                      isActive
                        ? "text-lightblue1 font-medium flex items-center"
                        : "text-border-lightblue1 font-medium flex items-center"
                    }
                    onClick={() => toggleMenu()}
                  >
                    Notifications{" "}
                    {notification.notifAmount ? (
                      <div className="relative w-4 h-4 bg-purple-200 rounded-full flex text-[10px] justify-center items-center text-center p-4 mx-3 shadow-xl text-white0 bg-lightblue1 ">
                        {notification.notifAmount}
                      </div>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </div>
              </li>
            )}
            {state?.role === UserRole.OWNER && (
              <li
                className={
                  pathname === "/member"
                    ? " flex  hover:bg-grey3 font-medium"
                    : "flex font-medium hover:bg-grey3"
                }
              >
                <div className="py-4 px-5 flex md:flex  items-center space-x-3  ">
                  {pathname === "/member" ? (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 18"
                      fill="#4368B1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C4.23858 0 2 2.23858 2 5C2 7.76142 4.23858 10 7 10C9.76142 10 12 7.76142 12 5C12 2.23858 9.76142 0 7 0ZM4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5C10 6.65685 8.65685 8 7 8C5.34315 8 4 6.65685 4 5Z"
                        fill="#4368B1"
                      />
                      <path
                        d="M14.9084 5.21828C14.6271 5.07484 14.3158 5.00006 14 5.00006V3.00006C14.6316 3.00006 15.2542 3.14956 15.8169 3.43645C15.8789 3.46805 15.9399 3.50121 16 3.5359C16.4854 3.81614 16.9072 4.19569 17.2373 4.65055C17.6083 5.16172 17.8529 5.75347 17.9512 6.37737C18.0496 7.00126 17.9987 7.63958 17.8029 8.24005C17.6071 8.84053 17.2719 9.38611 16.8247 9.83213C16.3775 10.2782 15.8311 10.6119 15.2301 10.8062C14.6953 10.979 14.1308 11.037 13.5735 10.9772C13.5046 10.9698 13.4357 10.9606 13.367 10.9496C12.7438 10.8497 12.1531 10.6038 11.6431 10.2319L11.6421 10.2311L12.821 8.61557C13.0761 8.80172 13.3717 8.92477 13.6835 8.97474C13.9953 9.02471 14.3145 9.00014 14.615 8.90302C14.9155 8.80591 15.1887 8.63903 15.4123 8.41602C15.6359 8.19302 15.8035 7.92024 15.9014 7.62001C15.9993 7.31978 16.0247 7.00063 15.9756 6.68869C15.9264 6.37675 15.8041 6.08089 15.6186 5.82531C15.4331 5.56974 15.1898 5.36172 14.9084 5.21828Z"
                        fill="#4368B1"
                      />
                      <path
                        d="M17.9981 18C17.9981 17.475 17.8947 16.9551 17.6938 16.47C17.4928 15.9849 17.1983 15.5442 16.8271 15.1729C16.4558 14.8017 16.0151 14.5072 15.53 14.3062C15.0449 14.1053 14.525 14.0019 14 14.0019V12C14.6821 12 15.3584 12.1163 16 12.3431C16.0996 12.3783 16.1983 12.4162 16.2961 12.4567C17.0241 12.7583 17.6855 13.2002 18.2426 13.7574C18.7998 14.3145 19.2417 14.9759 19.5433 15.7039C19.5838 15.8017 19.6217 15.9004 19.6569 16C19.8837 16.6416 20 17.3179 20 18H17.9981Z"
                        fill="#4368B1"
                      />
                      <path
                        d="M14 18H12C12 15.2386 9.76142 13 7 13C4.23858 13 2 15.2386 2 18H0C0 14.134 3.13401 11 7 11C10.866 11 14 14.134 14 18Z"
                        fill="#4368B1"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C4.23858 0 2 2.23858 2 5C2 7.76142 4.23858 10 7 10C9.76142 10 12 7.76142 12 5C12 2.23858 9.76142 0 7 0ZM4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5C10 6.65685 8.65685 8 7 8C5.34315 8 4 6.65685 4 5Z"
                        fill="#2E3A59"
                      />
                      <path
                        d="M14.9084 5.21828C14.6271 5.07484 14.3158 5.00006 14 5.00006V3.00006C14.6316 3.00006 15.2542 3.14956 15.8169 3.43645C15.8789 3.46805 15.9399 3.50121 16 3.5359C16.4854 3.81614 16.9072 4.19569 17.2373 4.65055C17.6083 5.16172 17.8529 5.75347 17.9512 6.37737C18.0496 7.00126 17.9987 7.63958 17.8029 8.24005C17.6071 8.84053 17.2719 9.38611 16.8247 9.83213C16.3775 10.2782 15.8311 10.6119 15.2301 10.8062C14.6953 10.979 14.1308 11.037 13.5735 10.9772C13.5046 10.9698 13.4357 10.9606 13.367 10.9496C12.7438 10.8497 12.1531 10.6038 11.6431 10.2319L11.6421 10.2311L12.821 8.61557C13.0761 8.80172 13.3717 8.92477 13.6835 8.97474C13.9953 9.02471 14.3145 9.00014 14.615 8.90302C14.9155 8.80591 15.1887 8.63903 15.4123 8.41602C15.6359 8.19302 15.8035 7.92024 15.9014 7.62001C15.9993 7.31978 16.0247 7.00063 15.9756 6.68869C15.9264 6.37675 15.8041 6.08089 15.6186 5.82531C15.4331 5.56974 15.1898 5.36172 14.9084 5.21828Z"
                        fill="#2E3A59"
                      />
                      <path
                        d="M17.9981 18C17.9981 17.475 17.8947 16.9551 17.6938 16.47C17.4928 15.9849 17.1983 15.5442 16.8271 15.1729C16.4558 14.8017 16.0151 14.5072 15.53 14.3062C15.0449 14.1053 14.525 14.0019 14 14.0019V12C14.6821 12 15.3584 12.1163 16 12.3431C16.0996 12.3783 16.1983 12.4162 16.2961 12.4567C17.0241 12.7583 17.6855 13.2002 18.2426 13.7574C18.7998 14.3145 19.2417 14.9759 19.5433 15.7039C19.5838 15.8017 19.6217 15.9004 19.6569 16C19.8837 16.6416 20 17.3179 20 18H17.9981Z"
                        fill="#2E3A59"
                      />
                      <path
                        d="M14 18H12C12 15.2386 9.76142 13 7 13C4.23858 13 2 15.2386 2 18H0C0 14.134 3.13401 11 7 11C10.866 11 14 14.134 14 18Z"
                        fill="#2E3A59"
                      />
                    </svg>
                  )}

                  <NavLink
                    to="/member"
                    className={({ isActive }) =>
                      isActive
                        ? "text-lightblue1 font-medium"
                        : "text-border-lightblue1 font-medium"
                    }
                    onClick={() => toggleMenu()}
                  >
                    Membres
                  </NavLink>
                </div>
              </li>
            )}

            {(state?.role === UserRole.OWNER ||
              state?.role === UserRole.MEMBER) && (
              <li className="flex md:flex flex-col ">
                <button
                  type="button"
                  className={
                    pathname.startsWith("/steer-ai")
                      ? " flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                      : "flex items-center py-4 px-5 w-full font-medium  text-gray-900  transition duration-75 group hover:bg-grey3"
                  }
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9863 10.1031L19.1795 14.7762C19.1791 14.7817 19.1791 14.7873 19.1795 14.7928L19.0726 15.4061C19.0351 15.6294 18.9215 15.8319 18.7519 15.9777C18.5824 16.1235 18.3678 16.2033 18.1462 16.2029H17.9649C17.9603 16.1983 17.9576 16.1927 17.954 16.189C17.777 15.976 17.5563 15.8054 17.3075 15.6895C17.0587 15.5736 16.7879 15.5151 16.5145 15.5183C15.926 15.527 15.3646 15.7731 14.9527 16.2029H11.2054C11.2009 16.1983 11.1981 16.1927 11.1936 16.189C11.0167 15.9761 10.7961 15.8056 10.5475 15.6897C10.2989 15.5738 10.0283 15.5152 9.75506 15.5183C9.16848 15.5272 8.60883 15.7714 8.19686 16.1983C8.06577 16.189 7.93805 16.1517 7.82199 16.0887C7.70592 16.0257 7.60411 15.9385 7.52317 15.8327C7.44222 15.7269 7.38394 15.6049 7.35212 15.4746C7.3203 15.3443 7.31564 15.2086 7.33844 15.0763L8.39084 8.97657H19.059C19.1963 8.97619 19.3321 9.00662 19.4567 9.06569C19.5813 9.12477 19.6917 9.21106 19.7801 9.31848C19.8685 9.4259 19.9328 9.55183 19.9684 9.68741C20.004 9.82298 20.0101 9.96489 19.9863 10.1031Z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#5B85D8" : "#282F75"
                      }
                    />
                    <path
                      d="M8.39115 8.97669H4.38641C4.33004 8.9766 4.27436 8.96392 4.22328 8.93954C4.1722 8.91515 4.12695 8.87966 4.09069 8.83554C4.05443 8.79142 4.02803 8.73974 4.01335 8.68411C3.99867 8.62848 3.99606 8.57024 4.0057 8.51347L4.38279 6.32704C4.39855 6.2355 4.44534 6.15256 4.51495 6.09276C4.58457 6.03296 4.67256 6.00012 4.7635 6H8.44372C8.50015 5.99994 8.5559 6.01251 8.60706 6.03684C8.65822 6.06117 8.70354 6.09666 8.73983 6.14082C8.77613 6.18498 8.80251 6.23673 8.81713 6.29243C8.83174 6.34813 8.83424 6.40643 8.82444 6.46323L8.39115 8.97669Z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#5B85D8" : "#282F75"
                      }
                    />
                    <path
                      d="M19.1795 14.7752C19.1791 14.7807 19.1791 14.7863 19.1795 14.7919L19.0726 15.4052C19.0351 15.6284 18.9215 15.8309 18.7519 15.9767C18.5824 16.1226 18.3678 16.2023 18.1462 16.2019H17.9649C17.9603 16.1973 17.9576 16.1917 17.954 16.188C17.777 15.975 17.5563 15.8045 17.3075 15.6885C17.0587 15.5726 16.7879 15.5141 16.5145 15.5173C15.926 15.526 15.3646 15.7721 14.9527 16.2019H11.2054C11.2009 16.1973 11.1981 16.1917 11.1936 16.188C11.0167 15.9751 10.7961 15.8046 10.5475 15.6887C10.2989 15.5728 10.0283 15.5143 9.75506 15.5173C9.16848 15.5262 8.60883 15.7704 8.19686 16.1973C8.06577 16.188 7.93805 16.1507 7.82199 16.0877C7.70592 16.0247 7.60411 15.9375 7.52317 15.8317C7.44222 15.7259 7.38394 15.6039 7.35212 15.4736C7.3203 15.3433 7.31564 15.2076 7.33844 15.0753L7.44541 14.462C7.48373 14.2403 7.59719 14.0394 7.76591 13.8945C7.93463 13.7497 8.14785 13.6702 8.36818 13.6699H9.47769C9.63032 13.6696 9.78058 13.6314 9.91558 13.5586C10.0506 13.4858 10.1663 13.3807 10.2527 13.2521L10.9253 12.246C11.0112 12.1169 11.1268 12.0113 11.2619 11.9385C11.397 11.8656 11.5476 11.8277 11.7003 11.8281H13.99C14.1165 11.828 14.2417 11.854 14.3581 11.9047C14.4744 11.9553 14.5796 12.0295 14.6672 12.1227L15.8456 13.3753C15.9332 13.4685 16.0383 13.5427 16.1547 13.5933C16.271 13.6439 16.3962 13.67 16.5227 13.6699H18.2522C18.3875 13.6701 18.5211 13.7001 18.644 13.7579C18.7669 13.8157 18.8761 13.9 18.9642 14.0049C19.0522 14.1099 19.1171 14.2331 19.1542 14.366C19.1914 14.4989 19.2 14.6385 19.1795 14.7752Z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#5B85D8" : "#282F75"
                      }
                    />
                    <path
                      d="M10.9138 17.6342C10.7833 18.3883 10.0808 18.9989 9.34293 18.9989C8.60508 18.9989 8.11377 18.3883 8.2434 17.6342C8.37302 16.8801 9.07734 16.2695 9.81429 16.2695C10.5512 16.2695 11.0425 16.8819 10.9138 17.6342Z"
                      fill="white"
                    />
                    <path
                      d="M17.592 17.6342C17.4633 18.3883 16.759 18.9989 16.0229 18.9989C15.2869 18.9989 14.7974 18.3883 14.9216 17.6342C15.0458 16.8801 15.7555 16.2695 16.4925 16.2695C17.2294 16.2695 17.7225 16.8819 17.592 17.6342Z"
                      fill="white"
                    />
                    <path
                      d="M10.9138 17.6342C10.7833 18.3883 10.0808 18.9989 9.34293 18.9989C8.60508 18.9989 8.11377 18.3883 8.2434 17.6342C8.37302 16.8801 9.07734 16.2695 9.81429 16.2695C10.5512 16.2695 11.0425 16.8819 10.9138 17.6342Z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#5B85D8" : "#282F75"
                      }
                    />
                    <path
                      d="M17.592 17.6342C17.4633 18.3883 16.759 18.9989 16.0229 18.9989C15.2869 18.9989 14.7974 18.3883 14.9216 17.6342C15.0458 16.8801 15.7555 16.2695 16.4925 16.2695C17.2294 16.2695 17.7225 16.8819 17.592 17.6342Z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#5B85D8" : "#282F75"
                      }
                    />
                    <path
                      d="M22.9228 8.44949L22.5113 9.70368C22.4285 9.95603 22.0715 9.95603 21.9887 9.70368L21.5772 8.44949C21.4954 8.20017 21.2998 8.00463 21.0505 7.92282L19.7963 7.51129C19.544 7.42849 19.544 7.07151 19.7963 6.98871L21.0505 6.57718C21.2998 6.49537 21.4954 6.29983 21.5772 6.05051L21.9887 4.79632C22.0715 4.54397 22.4285 4.54397 22.5113 4.79632L22.9228 6.05051C23.0046 6.29983 23.2002 6.49537 23.4495 6.57718L24.7037 6.98871C24.956 7.07151 24.956 7.42849 24.7037 7.51129L23.4495 7.92282C23.2002 8.00463 23.0046 8.20017 22.9228 8.44949Z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#5B85D8" : "#282F75"
                      }
                    />
                  </svg>

                  <li
                    onClick={() =>
                      setIsShowingSteerAi(
                        (isShowingSteerAi) => !isShowingSteerAi
                      )
                    }
                    className={
                      pathname.startsWith("/steer-ai")
                        ? "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-lightblue1  font-medium"
                        : "flex-1 ml-3 text-left whitespace-nowrap hover:bg-grey3 text-border-lightblue1 font-medium"
                    }
                  >
                    Steer AI
                  </li>
                  <svg
                    onClick={() =>
                      setIsShowingSteerAi(
                        (isShowingSteerAi) => !isShowingSteerAi
                      )
                    }
                    sidebar-toggle-item="true"
                    className="w-6 h-6 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      fill={
                        pathname.startsWith("/steer-ai") ? "#4368B1" : "#282F75"
                      }
                    />
                  </svg>
                </button>
                <Transition
                  show={isShowingSteerAi}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ul>
                    <li>
                      <Link
                        to="/steer-ai"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Steer chat
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/steer-ai/os"
                        className="flex items-center p-2 pl-11 w-full font-medium transition duration-75 group hover:bg-grey3 "
                        onClick={() => toggleMenu()}
                      >
                        Steer OS
                      </Link>
                    </li>
                  </ul>
                </Transition>
              </li>
            )}
          </>

          {(state?.role === UserRole.SUPER_ADMIN ||
            state?.role === UserRole.ADMIN) && (
            <>
              <li
                className={
                  pathname === "/users"
                    ? " flex  hover:bg-grey3 py-4 font-medium"
                    : "flex font-medium hover:bg-grey3 py-4"
                }
              >
                <div className="py-2 px-5 flex md:flex  items-center space-x-3  ">
                  {pathname === "/users" ? (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 18"
                      fill="#4368B1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C4.23858 0 2 2.23858 2 5C2 7.76142 4.23858 10 7 10C9.76142 10 12 7.76142 12 5C12 2.23858 9.76142 0 7 0ZM4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5C10 6.65685 8.65685 8 7 8C5.34315 8 4 6.65685 4 5Z"
                        fill="#4368B1"
                      />
                      <path
                        d="M14.9084 5.21828C14.6271 5.07484 14.3158 5.00006 14 5.00006V3.00006C14.6316 3.00006 15.2542 3.14956 15.8169 3.43645C15.8789 3.46805 15.9399 3.50121 16 3.5359C16.4854 3.81614 16.9072 4.19569 17.2373 4.65055C17.6083 5.16172 17.8529 5.75347 17.9512 6.37737C18.0496 7.00126 17.9987 7.63958 17.8029 8.24005C17.6071 8.84053 17.2719 9.38611 16.8247 9.83213C16.3775 10.2782 15.8311 10.6119 15.2301 10.8062C14.6953 10.979 14.1308 11.037 13.5735 10.9772C13.5046 10.9698 13.4357 10.9606 13.367 10.9496C12.7438 10.8497 12.1531 10.6038 11.6431 10.2319L11.6421 10.2311L12.821 8.61557C13.0761 8.80172 13.3717 8.92477 13.6835 8.97474C13.9953 9.02471 14.3145 9.00014 14.615 8.90302C14.9155 8.80591 15.1887 8.63903 15.4123 8.41602C15.6359 8.19302 15.8035 7.92024 15.9014 7.62001C15.9993 7.31978 16.0247 7.00063 15.9756 6.68869C15.9264 6.37675 15.8041 6.08089 15.6186 5.82531C15.4331 5.56974 15.1898 5.36172 14.9084 5.21828Z"
                        fill="#4368B1"
                      />
                      <path
                        d="M17.9981 18C17.9981 17.475 17.8947 16.9551 17.6938 16.47C17.4928 15.9849 17.1983 15.5442 16.8271 15.1729C16.4558 14.8017 16.0151 14.5072 15.53 14.3062C15.0449 14.1053 14.525 14.0019 14 14.0019V12C14.6821 12 15.3584 12.1163 16 12.3431C16.0996 12.3783 16.1983 12.4162 16.2961 12.4567C17.0241 12.7583 17.6855 13.2002 18.2426 13.7574C18.7998 14.3145 19.2417 14.9759 19.5433 15.7039C19.5838 15.8017 19.6217 15.9004 19.6569 16C19.8837 16.6416 20 17.3179 20 18H17.9981Z"
                        fill="#4368B1"
                      />
                      <path
                        d="M14 18H12C12 15.2386 9.76142 13 7 13C4.23858 13 2 15.2386 2 18H0C0 14.134 3.13401 11 7 11C10.866 11 14 14.134 14 18Z"
                        fill="#4368B1"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 0C4.23858 0 2 2.23858 2 5C2 7.76142 4.23858 10 7 10C9.76142 10 12 7.76142 12 5C12 2.23858 9.76142 0 7 0ZM4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5C10 6.65685 8.65685 8 7 8C5.34315 8 4 6.65685 4 5Z"
                        fill="#2E3A59"
                      />
                      <path
                        d="M14.9084 5.21828C14.6271 5.07484 14.3158 5.00006 14 5.00006V3.00006C14.6316 3.00006 15.2542 3.14956 15.8169 3.43645C15.8789 3.46805 15.9399 3.50121 16 3.5359C16.4854 3.81614 16.9072 4.19569 17.2373 4.65055C17.6083 5.16172 17.8529 5.75347 17.9512 6.37737C18.0496 7.00126 17.9987 7.63958 17.8029 8.24005C17.6071 8.84053 17.2719 9.38611 16.8247 9.83213C16.3775 10.2782 15.8311 10.6119 15.2301 10.8062C14.6953 10.979 14.1308 11.037 13.5735 10.9772C13.5046 10.9698 13.4357 10.9606 13.367 10.9496C12.7438 10.8497 12.1531 10.6038 11.6431 10.2319L11.6421 10.2311L12.821 8.61557C13.0761 8.80172 13.3717 8.92477 13.6835 8.97474C13.9953 9.02471 14.3145 9.00014 14.615 8.90302C14.9155 8.80591 15.1887 8.63903 15.4123 8.41602C15.6359 8.19302 15.8035 7.92024 15.9014 7.62001C15.9993 7.31978 16.0247 7.00063 15.9756 6.68869C15.9264 6.37675 15.8041 6.08089 15.6186 5.82531C15.4331 5.56974 15.1898 5.36172 14.9084 5.21828Z"
                        fill="#2E3A59"
                      />
                      <path
                        d="M17.9981 18C17.9981 17.475 17.8947 16.9551 17.6938 16.47C17.4928 15.9849 17.1983 15.5442 16.8271 15.1729C16.4558 14.8017 16.0151 14.5072 15.53 14.3062C15.0449 14.1053 14.525 14.0019 14 14.0019V12C14.6821 12 15.3584 12.1163 16 12.3431C16.0996 12.3783 16.1983 12.4162 16.2961 12.4567C17.0241 12.7583 17.6855 13.2002 18.2426 13.7574C18.7998 14.3145 19.2417 14.9759 19.5433 15.7039C19.5838 15.8017 19.6217 15.9004 19.6569 16C19.8837 16.6416 20 17.3179 20 18H17.9981Z"
                        fill="#2E3A59"
                      />
                      <path
                        d="M14 18H12C12 15.2386 9.76142 13 7 13C4.23858 13 2 15.2386 2 18H0C0 14.134 3.13401 11 7 11C10.866 11 14 14.134 14 18Z"
                        fill="#2E3A59"
                      />
                    </svg>
                  )}

                  <NavLink
                    to="/users"
                    className={({ isActive }) =>
                      isActive
                        ? "text-lightblue1 font-medium"
                        : "text-border-lightblue1 font-medium"
                    }
                  >
                    Users
                  </NavLink>
                </div>
              </li>

              <li
                className={
                  pathname === "/subscriptions"
                    ? " flex  hover:bg-grey3 py-4 font-medium"
                    : "flex font-medium hover:bg-grey3 py-4"
                }
              >
                <div className="py-2 px-5 flex md:flex  items-center space-x-3  ">
                  {pathname === "/subscriptions" ? (
                    <SubscriptionsIcon width={16} className="fill-blue1" />
                  ) : (
                    <SubscriptionsIcon width={16} className="fill-black1" />
                  )}

                  <NavLink
                    to="/subscriptions"
                    className={({ isActive }) =>
                      isActive
                        ? "text-lightblue1 font-medium"
                        : "text-border-lightblue1 font-medium"
                    }
                  >
                    Subscriptions
                  </NavLink>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
export default SideBar;
