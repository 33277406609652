import React, { useState, useRef, useMemo, useEffect } from "react";
import VideoCaptureModal from "./VideoCaptureModal";
import VideoRecordingIcon from "../videoRecordingIcon/videoRecordingIcon";
import StepsNav from "../../stepsNavigator";
import { useSelector } from "react-redux";
import SteerSlider from "./Car360Motion";
import axios from "axios";
import { MoonLoader } from "react-spinners";

const flickityOptions = {
  pageDots: false,
  wrapAround: true,
  contain: true,
  draggable: false,
  prevNextButtons: false,
  selectedAttraction: 0.01,
  friction: 0.015,
  percentPosition: true,
  cellAlign: "center",
};

function Car360Viewer({ next, goBack, handleNext }) {
  const [openModal, setOpenModal] = useState(false);
  const [isCaptured, setIsCaptured] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null); // Combined state for recorded video and selected file
  const [menuOpen, setMenuOpen] = useState(false);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const carId = useSelector((state) => state).cars.car._id;
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [canGoNext, setCanGoNext] = useState(false);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const handleStartCapture = () => {
    setIsRecording(true);
    setVideoBlob(null); // Clear previous video blob
    startRecording();
  };

  const handleStopCapture = () => {
    setIsRecording(false);
    stopRecording();
    setIsCaptured(true);
    setOpenModal(false);
    setCanGoNext(false);
  };

  const handleCloseCamera = () => {
    setIsRecording(false);
    setIsCaptured(false);
    setVideoBlob(null);
    setCanGoNext(false);

    if (
      webcamRef.current &&
      webcamRef.current.video &&
      webcamRef.current.video.srcObject
    ) {
      const tracks = webcamRef.current.video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchCarImages = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CAR_SERVICE}/api/car/get-image/${carId}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        if (response.data.images && response.data.images.length > 0) {
          setImages(response.data.images);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          console.warn("No images found (404)");
          setImages([]);
        } else {
          console.error("Error fetching car images:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCarImages();
  }, [carId]);

  const startRecording = () => {
    const stream = webcamRef.current.video.srcObject;
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });

    mediaRecorderRef.current.ondataavailable = handleDataAvailable;
    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setVideoBlob(new Blob([event.data], { type: "video/webm" }));
    }
  };

  // Handle video file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideoBlob(file);
      setIsCaptured(true);
    }
    setCanGoNext(false);
  };

  const [preventDefaultActive, setPreventDefaultActive] = useState(true);

  const togglePreventDefault = () => {
    setPreventDefaultActive((prev) => !prev);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("files", videoBlob);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CAR_SERVICE}/api/car/video-to-image/${carId}`,
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        const response = await axios.get(
          `${process.env.REACT_APP_CAR_SERVICE}/api/car/get-image/${carId}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setImages(response.data.images);
        setCanGoNext(true);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
      setCanGoNext(false);
    } finally {
      setLoading(false);
    }
  };

  const videoElement = useMemo(
    () =>
      isCaptured && videoBlob ? (
        <video
          controls
          src={URL.createObjectURL(videoBlob)} // Use videoBlob directly
          className="w-[90%] rounded-md"
        />
      ) : null,
    [isCaptured, videoBlob]
  );

  return (
    <>
      <div className="bg-white w-full xl:w-[400px] flex-shrink-0 sm:mx-auto rounded-md md:border md:border-grey2 overflow-hidden relative">
        <StepsNav next={next} back={goBack} />
        <div className="flex justify-center lg:rounded-md xxl:rounded-md overflow-y-auto relative flex-col min-h-[300px]">
          <div className="flex flex-col justify-center w-full items-center">
            {videoElement}
          </div>
          <div
            className={`${
              isCaptured ? "mt-5" : "mt-[30%]"
            } relative flex flex-col items-center justify-center`}
          >
            <button
              onClick={() => setMenuOpen((prev) => !prev)}
              className="w-[90%] px-10 py-5 bg-gray-300 text-black font-bold rounded-md hover:bg-gray-500 focus:outline-none"
            >
              {isCaptured ? "Retake new view" : "Upload a new view"}
            </button>

            {/* Dropdown Menu */}
            {menuOpen && (
              <div className="py-1/2 rounded-md bg-gray-100 w-[90%]">
                <button
                  onClick={() => {
                    setOpenModal(true);
                    setMenuOpen(false);
                  }}
                  className="w-full block rounded-md px-4 py-2 text-sm text-gray-700 hover:text-white hover:bg-gray-500"
                >
                  Open Camera
                </button>
                <button
                  onClick={() => {
                    document.getElementById("fileInput").click();
                    setMenuOpen(false);
                  }}
                  className="w-full block rounded-md px-4 py-2 text-sm text-gray-700 hover:text-white hover:bg-gray-500"
                >
                  Choose from PC
                </button>
              </div>
            )}

            {/* Hidden file input for selecting video from PC */}
            <input
              id="fileInput"
              type="file"
              accept="video/*"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </div>

          {isRecording && (
            <div className="absolute top-0 right-0 left-0 flex text-[14px] overflow-hidden w-14 items-center justify-center rounded-full m-3 flex-wrap bg-white drop-shadow-md">
              <VideoRecordingIcon isRecording={isRecording} />
            </div>
          )}
        </div>
        <div className="block xxl:absolute  bottom-0 right-0 left-0 p-5">
          {canGoNext || !videoBlob ? (
            <button
              onClick={(e) => handleNext(e)}
              className="mt-2 box-border h-[52px] w-full rounded-lg text-white0 bg-blue1 font-bold text-[20px] border-blue1 drop-shadow-md bg-blue1 text-white0"
            >
              Suivant
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="mt-2 box-border h-[52px] w-full rounded-lg text-white0 bg-blue1 font-bold text-[20px] border-blue1 drop-shadow-md bg-blue1 text-white0"
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <div className="xxl:block min-h-[590px] xxl:w-2/3 drop-shadow-lg sm:p-2 xl:w-full h-fit pt-10 pb-20 px-10">
        <h3 className="text-[19px] text-warning1 font-bold mb-[25px]">
          Car Preview
        </h3>

        {loading ? (
          <div className="flex flex-row justify-center items-center w-full pt-10">
            <MoonLoader />
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full justify-center items-center">
              {/* // ALREADY EXIST COMPONENT */}
              <SteerSlider
                options={flickityOptions}
                steerImages={images}
                preventDefaultActive={preventDefaultActive}
              />
              {images?.length > 0 && (
                <button
                  onClick={togglePreventDefault}
                  className="mt-2 box-border w-[350px] h-[35px] rounded-lg text-white0 bg-blue1 font-bold text-[16px] border-blue1 drop-shadow-md bg-blue1 text-white0"
                >
                  {preventDefaultActive ? "Enable" : "Disable"} the 360° view
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <VideoCaptureModal
        open={openModal}
        close={
          isCaptured && !isRecording
            ? () => setOpenModal(false)
            : handleCloseCamera
        }
        webcamRef={webcamRef}
        isCaptured={isCaptured}
        isRecording={isRecording}
        handleStartCapture={handleStartCapture}
        handleStopCapture={handleStopCapture}
        videoConstraints={videoConstraints}
      />
    </>
  );
}

export default Car360Viewer;
